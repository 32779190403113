<template>
  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-header card-header-with-btn bg-light">
          <div class="card-title fs25">ACL Users</div>
        </div>
        <div class="card-body" v-loading="state.loading">

          <!--list-->
          <ItemTable
            :data="state.users"
            :columns="state.columnMap"
            :show-actions="isAuthorized(permissions.acl_user_update)"
          >
            <template #groups="{item}">
              <span v-html="renderGroups(item.groups)"/>
            </template>

            <template #action="{item}">
              <EditButton
                v-if="isAuthorized(permissions.acl_user_update)"
                @click="handleAction(item, constants.LIST_ITEM_ACTION_UPDATE)"
              />
            </template>

          </ItemTable>

          <!--update Modal-->
          <UserAction
            :show="state.showAction"
            :user="state.action.subject"
            :updating="state.action.types.edit"
            @hide="handleActionToggle"
          />

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, reactive, watch } from 'vue';
import ItemTable from '@/components/Util/ItemTable';
import ButtonComp from '@/components/Util/ButtonComp';
import { useStore } from 'vuex';
import { constants } from '@/utils';
import UserAction from '@/components/AccessControl/UserAction';
import permissions from '@/utils/permissions';
import { isAuthorized } from '@/utils/Helper';
import EditButton from '@/components/Util/ListActions/EditButton';

export default {
  name: 'Users',
  components: { EditButton, UserAction, ItemTable, ButtonComp },
  setup () {

    const store = useStore();
    const userStore = store.state.acl.users;

    const state = reactive({
      showAction: false,

      loading: false,

      users: [],
      columnMap: [
        { field: 'name', header: 'Name' },
        { field: 'email', header: 'Email', sortable: true },
        { field: 'groups', header: 'Groups', sortable: true },
      ],

      action: {
        types: {
          edit: false,
        },
        subject: false,
        deleteLoading: false
      },
    });

    watch(userStore, (nv) => {
      state.users = nv.data?.users || [];
    });

    onMounted(async () => {
      state.loading = true;

      await store.dispatch('acl/fetchUsers');
      await store.dispatch('acl/fetchGroups');
      await store.dispatch('acl/fetchPermissions');

      state.loading = false;
    });

    const renderGroups = (groups) => {
      if (!groups?.length) return 'N/A';

      return groups.map(group => `<span class="badge table-badge badge-primary badge-pill fs15">${group.name}</span>`);
    };

    const handleActionToggle = (value) => {

      // set the desired action or just toggle
      const newValue = !!value || !state.showAction;

      state.showAction = newValue;

      // if closing the action then make edit false
      if (!newValue) {
        state.action.types.edit = false;
        state.action.subject = false;
      }

    };

    const handleAction = (item, actionType) => {

      state.action.subject = item;

      switch (actionType) {
        case constants.LIST_ITEM_ACTION_UPDATE:
          if (!isAuthorized(permissions.acl_user_update)) return;
          state.action.types.edit = true;
          handleActionToggle();
          break;
      }

    };

    return {
      state,
      constants,
      renderGroups,
      handleAction,
      handleActionToggle,
      permissions,
      isAuthorized
    };
  }
};
</script>
